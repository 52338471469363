import React, {useState} from 'react'
import GradientBtn from '@ui/GradientBtn'
import { NavLink, useParams } from 'react-router-dom'
import styles from './style.module.scss';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL


const NewPassword = () => {
    const [pword, setPassword] = useState("")
    const [cpword, setCPassword] = useState("")
    
    const [isLogged, setIsLogged] = useState(false)
    const navigate = useNavigate()
    const {key} = useParams()

    const handleSubmit = (e) => {
        e.preventDefault()
        if(pword === cpword){
        const data = {
            pword,
            cpword
        }

        axios.post(`${apiUrl}/new-password?id=${key}`, data,
        {headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        )
        .then((res)=>{
            console.log(res.data)
            if( true){
                Swal.fire('Great','Password changed successfully','success')
               
            }
             
        })

    }
    else{
        Swal.fire('Error','Password does not match','error')
    }
}

  return (
    <div className={`${styles.container} border-10`}>
            <div className={styles.main}>
                <h3>Create New Password</h3>
                 
                <div className={styles.main_form}>
                  
                    <form className="main_form-form d-flex flex-column g-20" onSubmit={handleSubmit} encType='multipart/form-data'>
                        
                               
                                <input className="field field--outline"
                                    type="password"
                                    id="pword"
                                    placeholder="New Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={pword}
                                    />

                                <input className="field field--outline"
                                    type="password"
                                    id="cpword"
                                    placeholder="Confirm Password"
                                    onChange={(e) => setCPassword(e.target.value)}
                                    value={cpword}
                                    />
                          

                         
                      

                        <GradientBtn className={styles.btn} tag="button" type="submit">Change Password</GradientBtn>
                    </form>
                  
                </div>
            </div>
          
        </div>
  )
}

export default NewPassword