import React, {useState} from 'react'
import GradientBtn from '@ui/GradientBtn'
import { NavLink } from 'react-router-dom'
import styles from './style.module.scss';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL


const Forgot = () => {
    const [email, setEmail] = useState("")
    
    const [isLogged, setIsLogged] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            email
        }

        axios.post(`${apiUrl}/forgot`, data,
        {headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        )
        .then((res)=>{
            console.log(res.data)
            if( true){
                Swal.fire('Great','Password reset link sent. It may take a few minutes to arrive','success')
               
            }
             
        })

    }
  return (
    <div className={`${styles.container} border-10`}>
            <div className={styles.main}>
                <h3>Forgot your Password?</h3>
                <p>Enter the email address linked to your account</p>
              
                <div className={styles.main_form}>
                  
                    <form className="main_form-form d-flex flex-column g-20" onSubmit={handleSubmit} encType='multipart/form-data'>
                        
                               
                                <input className="field field--outline"
                                    type="text"
                                    id="email"
                                    placeholder="Email Address"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    />
                          

                         
                      

                        <GradientBtn className={styles.btn} tag="button" type="submit">Reset Password</GradientBtn>
                    </form>
                  
                </div>
            </div>
          
        </div>
  )
}

export default Forgot